import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { ReactSVG } from "react-svg";
import {useSelector} from "react-redux";

const Breadcrumb = ({ pageTitle, prevUrl, backText='Back' }) => {

  const cartHasItems = useSelector(s => s.cartData.cartHasItems);

  return (
    <div className="breadcrumb-area bg-color--grey space-y--15">
        {
            prevUrl && <Link to={process.env.PUBLIC_URL + prevUrl} className="back-link">
                {" "}
                <FaAngleLeft /> {backText}
            </Link>
        }

      <h4 className="page-title text-center">{pageTitle}</h4>



      <Link to={process.env.PUBLIC_URL + "/cart"} className="cart">
        <ReactSVG
          className={cartHasItems ? 'cart-has-items' : ''}
          src={process.env.PUBLIC_URL + "/assets/img/icons/cart.svg"}
        />
      </Link>
    </div>
  );
};

Breadcrumb.propTypes = {
  pageTitle: PropTypes.string,
  prevUrl: PropTypes.string
};

export default Breadcrumb;

import React, {lazy, Suspense} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import AppRoute from "./helpers/AppRoute";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/style.scss";

import {MenuLayout} from "./layouts";
import {Preloader} from "./components";
import ScrollToTop from "./helpers/scroll-top";
import {fetchConfig} from "./redux/actions/config";
import {STORE_OPEN} from "./consts";

const Landing = lazy(() => import("./pages/Landing"));
const Products = lazy(() => import("./pages/Products"));
const Category = lazy(() => import("./pages/Category"));
const Cart = lazy(() => import("./pages/Cart"));
const Checkout = lazy(() => import("./pages/Checkout"));
const CheckoutComplete = lazy(() => import("./pages/CheckoutComplete"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Contact = lazy(() => import("./pages/Contact"));
const Pay = lazy(() => import("./pages/Pay"));

function App() {

  const dispatch = useDispatch();
  const categories = useSelector(s => s.config.categories);

  React.useEffect(() => dispatch(fetchConfig()), []);

  const renderRoutes = () => {
    if (categories.length === 0) {
      return <Preloader/>
    }
    return (
      <Switch>
        <Route
          path={process.env.PUBLIC_URL + "/"}
          exact
          component={Landing}
        />
        {
          STORE_OPEN && <>
            <AppRoute
              path={process.env.PUBLIC_URL + "/category/:id"}
              component={Category}
              layout={MenuLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/category"}
              component={Products}
              layout={MenuLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/cart/checkout/done"}
              component={CheckoutComplete}
              layout={MenuLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/cart/checkout"}
              component={Checkout}
              layout={MenuLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/cart"}
              component={Cart}
              layout={MenuLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/not-found"}
              component={NotFound}
              layout={MenuLayout}
            />
          </>
        }
        <AppRoute
          path={process.env.PUBLIC_URL + "/contact"}
          component={Contact}
          layout={MenuLayout}
        />
        <Route
          path={process.env.PUBLIC_URL + "/pay/:reference/:amount"}
          component={Pay}
        />
        <AppRoute exact component={NotFound} layout={MenuLayout}/>
      </Switch>
    )
  }
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Preloader/>}>
          {renderRoutes()}
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;

import {CLEAR_GO_TO_ORDER_CONFIRMED, ORDER_FAILED, ORDER_PLACED, PLACE_ORDER} from "../actions/orders";


const initState = {
  order: {},
  orderPending: false,
  failed: false,
  goToOrderConfirmed: false,
};

const order = (state = initState, action) => {

  if (action.type === PLACE_ORDER) {
    return {
      ...state,
      order: {},
      failed: false,
      orderPending: true
    }
  }

  if (action.type === ORDER_PLACED) {
    return {
      ...state,
      order: action.payload.order,
      orderPending: false,
      failed: false,
      goToOrderConfirmed: true
    }
  }

  if (action.type === CLEAR_GO_TO_ORDER_CONFIRMED) {
    return {
      ...state,
      goToOrderConfirmed: false
    }
  }

  if (action.type === ORDER_FAILED) {
    return {
      failed: true,
      orderPending: false
    }
  }


  return state;
};

export default order;

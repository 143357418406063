import {v4 as uuid} from "uuid";
import {
  ADD_BOX_TO_CART,
  ADD_TO_CART,
  DELETE_ALL_FROM_CART,
  REMOVE_FROM_CART,
  SET_ADDITIONAL_OPTIONS,
} from "../actions/cartActions";

const initState = {
  items: {},
  orderTotal: 0,
  totalQuantity: 0,
  cartHasItems: false,
  charges: {
    deliveryCharge: null,
    serviceCharge: null
  },
  additionalOptions: {},
  grandTotal: null,
  additionalOptionsComplete: false
};

const isDistributionOrder = (cart) => {
  const item_skus = Object.keys(cart)
  return item_skus.includes('angora-2024-distribution') && item_skus.length === 1;
};

const calculateDeliveryFees = ({service, qurbaniDeliveryOption, deliveryArea}) => {
  if (service === 'COLLECT_LIVE_ANIMALS') return 0; // FREE
  if (service === 'DELIVER_LIVE_ANIMALS') return null; // TBC
  if (service === 'QURBANI_AT_FARM') {
    if (qurbaniDeliveryOption === 'COLLECT_CENTRAL_POINT' || qurbaniDeliveryOption === 'DISTRIBUTE') return 0 // FREE
    if (qurbaniDeliveryOption === 'DELIVER_TO_DOOR' && deliveryArea === 'Stanger and surrounding areas') return 0; // FREE
    if (qurbaniDeliveryOption === 'DELIVER_TO_DOOR' && [
      'Durban and surrounding areas',
      'Empangeni',
    ].includes(deliveryArea)) return 0; // delivery to door
    return null; // Default for all areas
  }

  return null; // Default is TBC
}

const calculateServiceFees = ({service}, totalQuantity, cart = {}) => {
  if (service === 'COLLECT_LIVE_ANIMALS') return 0; // FREE
  if (service === 'DELIVER_LIVE_ANIMALS') return 0; // FREE
  if (isDistributionOrder(cart)) return 0

  if (!service) return null;
  return 300 * totalQuantity;
}

const deriveAdditionalFees = (additionalOptions, state) => {
  const {totalQuantity, orderTotal, items} = state;
  const {service, qurbaniDeliveryOption, deliveryAddress, specialRequest, deliveryArea} = additionalOptions
  const additionalOptionsComplete = (service === 'COLLECT_LIVE_ANIMALS') ||
    (qurbaniDeliveryOption === 'DISTRIBUTE') ||
    !!(service === 'DELIVER_LIVE_ANIMALS' && deliveryAddress) ||
    !!(qurbaniDeliveryOption === 'COLLECT_CENTRAL_POINT' && deliveryArea) ||
    !!(service === 'QURBANI_AT_FARM' && qurbaniDeliveryOption === 'SPECIAL_REQUEST' && specialRequest) ||
    !!(service === 'QURBANI_AT_FARM' && qurbaniDeliveryOption === 'DELIVER_TO_DOOR' && deliveryArea && deliveryAddress)

  let charges = {
    serviceCharge: calculateServiceFees(additionalOptions, totalQuantity, items),
    deliveryCharge: calculateDeliveryFees(additionalOptions)
  }


  return {
    additionalOptions,
    charges,
    additionalOptionsComplete,
    grandTotal: charges.serviceCharge === null || charges.deliveryCharge === null ? null : (charges.deliveryCharge + charges.serviceCharge + orderTotal)
  }
}

const cartReducer = (state = initState, action) => {

  if (action.type === ADD_TO_CART) {
    const id = action.payload.cartId;
    let newState = {...state};

    if (!newState.items) {
      newState.items = {}
    } else {
      newState.items = {
        ...newState.items
      }
    }
    const quantity = ((state.items[id] && state.items[id].quantity) || 0) + 1;


    let newItem = {};
    if (state.items[id] && state.items[id].boxId) {
      newItem = {
        ...state.items[id],
        quantity,
        linePrice: (state.items[id].packagingCost + state.items[id].itemsCost) * quantity
      }
    } else {
      newItem = {
        productId: id,
        quantity,
        linePrice: action.payload.product.price * quantity
      }
    }

    newState.items[id] = newItem;

    const orderTotal = Object.keys(newState.items).reduce((total, key) => total + newState.items[key].linePrice, 0);
    newState.orderTotal = orderTotal || 0;

    const totalQuantity = Object.keys(newState.items).reduce((total, key) => total + newState.items[key].quantity, 0);
    newState.totalQuantity = totalQuantity || 0;

    newState.cartHasItems = Object.keys(newState.items).length > 0;
    return {
      ...newState,
      ...deriveAdditionalFees(state.additionalOptions, newState)
    };
  }

  if (action.type === REMOVE_FROM_CART) {
    const id = action.payload.cartId;
    let newState = {...state};

    if (!newState.items) {
      newState.items = {}
    } else {
      newState.items = {
        ...newState.items
      }
    }

    const quantity = ((state.items[id] && state.items[id].quantity) || 0) - 1;

    let newItem = {};
    if (state.items[id] && state.items[id].boxId) {
      newItem = {
        ...state.items[id],
        quantity,
        linePrice: (state.items[id].packagingCost + state.items[id].itemsCost) * quantity
      }
    } else {
      newItem = {
        productId: id,
        quantity,
        linePrice: action.payload.product.price * quantity
      }
    }

    newState.items[id] = newItem;

    if (newState.items[id].quantity <= 0)
      delete newState.items[id];

    const orderTotal = Object.keys(newState.items).reduce((total, key) => total + newState.items[key].linePrice, 0);
    newState.orderTotal = orderTotal || 0;

    const totalQuantity = Object.keys(newState.items).reduce((total, key) => total + newState.items[key].quantity, 0);
    newState.totalQuantity = totalQuantity || 0;

    newState.cartHasItems = Object.keys(newState.items).length > 0;

    return {
      ...newState,
      ...deriveAdditionalFees(state.additionalOptions, newState)
    };
  }

  if (action.type === ADD_BOX_TO_CART) {
    const boxId = uuid();
    const id = action.payload.product.id;
    let newState = {...state};

    if (!newState.items) {
      newState.items = {}
    } else {
      newState.items = {
        ...newState.items
      }
    }
    const quantity = ((state.items[id] && state.items[id].quantity) || 0) + 1;
    const linePrice = (action.payload.packagingCost + action.payload.itemsCost) * quantity;
    newState.items[boxId] = {
      productId: id,
      boxId: boxId,
      quantity,
      linePrice,
      selectedItems: action.payload.selectedItems,
      packagingCost: action.payload.packagingCost,
      itemsCost: action.payload.itemsCost
    };


    const orderTotal = Object.keys(newState.items).reduce((total, key) => total + newState.items[key].linePrice, 0);
    newState.orderTotal = orderTotal || 0;


    const totalQuantity = Object.keys(newState.items).reduce((total, key) => total + newState.items[key].quantity, 0);
    newState.totalQuantity = totalQuantity || 0;

    newState.cartHasItems = Object.keys(newState.items).length > 0;
    return {
      ...newState,
      ...deriveAdditionalFees(state.additionalOptions, newState)
    };
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return {
      items: {},
      orderTotal: 0,
      totalQuantity: 0,
      cartHasItems: false,
      charges: {
        deliveryCharge: null,
        serviceCharge: null
      },
      grandTotal: null,
      additionalOptions: {},
      additionalOptionsComplete: false
    }
  }

  if (action.type === SET_ADDITIONAL_OPTIONS) {
    return {
      ...state,
      ...deriveAdditionalFees(action.payload, state)
    }
  }


  // return [...cartItems, action.payload];

  // // for non variant products
  // if (product.variation === undefined) {
  //   const cartItem = cartItems.filter(item => item.id === product.id)[0];
  //   if (cartItem === undefined) {
  //     return [
  //       ...cartItems,
  //       {
  //         ...product,
  //         quantity: product.quantity ? product.quantity : 1,
  //         cartItemId: uuid()
  //       }
  //     ];
  //   } else {
  //     return cartItems.map(item =>
  //       item.cartItemId === cartItem.cartItemId
  //         ? {
  //             ...item,
  //             quantity: product.quantity
  //               ? item.quantity + product.quantity
  //               : item.quantity + 1
  //           }
  //         : item
  //     );
  //   }
  //   // for variant products
  // } else {
  //   const cartItem = cartItems.filter(
  //     item =>
  //       item.id === product.id &&
  //       product.selectedProductColor &&
  //       product.selectedProductColor === item.selectedProductColor &&
  //       (product.cartItemId ? product.cartItemId === item.cartItemId : true)
  //   )[0];
  //
  //   if (cartItem === undefined) {
  //     return [
  //       ...cartItems,
  //       {
  //         ...product,
  //         quantity: product.quantity ? product.quantity : 1,
  //         cartItemId: uuid()
  //       }
  //     ];
  //   } else if (
  //     cartItem !== undefined &&
  //     cartItem.selectedProductColor !== product.selectedProductColor
  //   ) {
  //     return [
  //       ...cartItems,
  //       {
  //         ...product,
  //         quantity: product.quantity ? product.quantity : 1,
  //         cartItemId: uuid()
  //       }
  //     ];
  //   } else {
  //     return cartItems.map(item =>
  //       item.cartItemId === cartItem.cartItemId
  //         ? {
  //             ...item,
  //             quantity: product.quantity
  //               ? item.quantity + product.quantity
  //               : item.quantity + 1,
  //             selectedProductColor: product.selectedProductColor
  //           }
  //         : item
  //     );
  //   }
  // }

  // if (action.type === DECREASE_QUANTITY) {
  //   if (product.quantity === 1) {
  //     const remainingItems = (cartItems, product) =>
  //       cartItems.filter(
  //         cartItem => cartItem.cartItemId !== product.cartItemId
  //       );
  //     return remainingItems(cartItems, product);
  //   } else {
  //     return cartItems.map(item =>
  //       item.cartItemId === product.cartItemId
  //         ? { ...item, quantity: item.quantity - 1 }
  //         : item
  //     );
  //   }
  // }
  //
  // if (action.type === DELETE_FROM_CART) {
  //   const remainingItems = (cartItems, product) =>
  //     cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
  //   return remainingItems(cartItems, product);
  // }
  //
  // if (action.type === DELETE_ALL_FROM_CART) {
  //   return cartItems.filter(item => {
  //     return false;
  //   });
  // }

  return state;
};

export default cartReducer;

export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_BOX_TO_CART = "ADD_BOX_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const SET_ADDITIONAL_OPTIONS = "SET_ADDITIONAL_OPTIONS";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

export const addToCart = (cartId, product) => ({type: ADD_TO_CART, payload: {cartId, product}});
export const addBoxToCart = (product, selectedItems, packagingCost, itemsCost) => ({
  type: ADD_BOX_TO_CART,
  payload: {
    product,
    selectedItems,
    packagingCost,
    itemsCost
  }
});
export const removeFromCart = (cartId, product) => ({type: REMOVE_FROM_CART, payload: {cartId, product}});
export const setAdditionalOptions = options => ({type: SET_ADDITIONAL_OPTIONS, payload: options})

//add to cart
export const addToCartDispatch = (
  item,
  quantityCount,
  selectedProductColor
) => {
  return dispatch => {
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
            ? item.selectedProductColor
            : null
      }
    });
  };
};

//delete from cart
export const deleteFromCartDispatch = item => {
  return dispatch => {
    dispatch({type: DELETE_FROM_CART, payload: item});
  };
};
//delete all from cart
export const deleteAllFromCartDispatch = () => {
  return dispatch => {
    dispatch({type: DELETE_ALL_FROM_CART});
  };
};

// get stock of cart item
export const cartItemStock = (item, color) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation.filter(single => single.color === color)[0].stock;
  }
};

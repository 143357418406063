import React from "react";
import {Link} from "react-router-dom";

const Header = (props) => (
  <header>
    <div className="header-wrapper border-bottom">
      <div className="container space-y--5">
        <div className="row align-items-center">
          <div className="col-auto center-logo">
            <div className="header-logo">
              <Link to={process.env.PUBLIC_URL + "/"}>
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/KJLBanner.png"}
                  className="img-fluid "
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);


export default Header;

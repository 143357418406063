import React from "react";
import {withRouter} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import {useDispatch, useSelector} from "react-redux";
import {setAdditionalOptions} from "../../redux/actions/cartActions";


const serviceOptions = {
  'QURBANI_AT_FARM': 'Perform the Qurbani at the farm (either in person or by a staff member on your behalf)',
  'DELIVER_LIVE_ANIMALS': 'Deliver the live animals to me',
  'COLLECT_LIVE_ANIMALS': 'I will collect the live animals from Stanger',
}

const deliveryOptions = {
  // COLLECT_CENTRAL_POINT: 'Collect from central point',
  DELIVER_TO_DOOR: 'Deliver to my door',
  DISTRIBUTE: 'Distribute on my behalf (via local organization)',
  SPECIAL_REQUEST: 'Other special request',
}

const distributeDeliveryOptions = {
  // COLLECT_CENTRAL_POINT: 'Collect from central point',
  DISTRIBUTE: 'Distribute on my behalf (via local organization)',
}

const deliveryAreas = [
  'Stanger and surrounding areas',
  'Durban and surrounding areas',
  'Other special request'
]

const collectionAreas = ['Stanger', 'Durban']


const hasDistributionItemsMixedWithRegularItems = (cart) => {
  const item_skus = Object.keys(cart)
  return item_skus.includes('angora-2024-distribution') && item_skus.length > 1;
};

const isDistributionOrder = (cart) => {
  const item_skus = Object.keys(cart)
  return item_skus.includes('angora-2024-distribution') && item_skus.length === 1;
};


const distributionHelperText = (isDistribution) => {
  if (isDistribution) return ""

  return "A facility service fee of R 300 / animal will be added to your order. This covers the skinning of the animal, storage of the carcass and slicing as per your specifications."
}

const ServiceSelector = () => {
  const dispatch = useDispatch();

  const {additionalOptions, items} = useSelector(s => s.cartData);
  const isCartValid = !hasDistributionItemsMixedWithRegularItems(items)
  const isDistribtion = isDistributionOrder(items)

  const [values, setValues] = React.useState(additionalOptions || {
    service: '',
    presentAtQurbani: '',
    qurbaniDeliveryOption: '',
    deliveryArea: '',
    specialRequest: '',
    deliveryAddress: ''
  })

  const requiresDeliveryInfo = ['DELIVER_TO_DOOR'].includes(values.qurbaniDeliveryOption) || (values.service === 'DELIVER_LIVE_ANIMALS')

  React.useEffect(() => {
    dispatch(setAdditionalOptions(values))
  }, [dispatch, values])


  const setValue = (key, value) => {
    if (key === 'service') {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: value,
        presentAtQurbani: '',
        qurbaniDeliveryOption: '',
        deliveryArea: '',
        specialRequest: '',
        deliveryAddress: '',
      }))
    } else if (key === 'presentAtQurbani') {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: value,
        qurbaniDeliveryOption: '',
        deliveryArea: '',
        specialRequest: '',
      }))
    } else if (key === 'qurbaniDeliveryOption') {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: value,
        deliveryArea: '',
        specialRequest: ''
      }))
    } else if (key === 'deliveryArea') {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: value,
        deliveryAddress: ''
      }))
    } else if (key === 'specialRequest') {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      }))
    } else if (key === 'deliveryAddress') {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      }))
    }
  }

  if (!isCartValid) return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="checkout-form" style={{paddingTop: 20}}>
            <div>
              <p className="additional-info red-text">
                Please place a separate order for distribution only items. Remove the distribution items from your
                current
                order to continue.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const hasSpecialRequest = values.qurbaniDeliveryOption === 'SPECIAL_REQUEST';
  return (

    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="checkout-form" style={{paddingTop: 20}}>
            <div>
              <Form.Group style={{textAlign: 'left', fontSize: '1.2em', paddingBottom: 10}}>
                <Form.Label>Which service do you require?</Form.Label>
                {
                  Object.keys(serviceOptions).map(id => (
                    <Form.Check
                      type='radio'
                      name='service'
                      id={id}
                      key={id}
                      checked={values.service === id}
                      onChange={(e) => setValue('service', id)}
                      label={serviceOptions[id]}
                    />
                  ))
                }
              </Form.Group>
              {
                values.service === 'DELIVER_LIVE_ANIMALS' && (
                  <p className="additional-info">
                    Please provide your delivery address and we will confirm delivery costs for live animals with you
                  </p>
                )
              }
              {
                values.service === 'QURBANI_AT_FARM' && (
                  <>
                    <p className="additional-info">
                      {
                        distributionHelperText(isDistribtion)
                      }
                    </p>
                    <Form.Group style={{textAlign: 'left', fontSize: '1.2em', paddingBottom: 10}}>
                      <Form.Label>Will you be present when the Qurbani is performed?</Form.Label>
                      <Form.Check
                        type='radio'
                        name='presentAtQurbani'
                        id={'yes'}
                        checked={values.presentAtQurbani === 'yes'}
                        onChange={(e) => setValue('presentAtQurbani', 'yes')}
                        label="I will be present at the farm"
                      />
                      <Form.Check
                        type='radio'
                        name='presentAtQurbani'
                        id={'no'}
                        checked={values.presentAtQurbani === 'no'}
                        onChange={(e) => setValue('presentAtQurbani', 'no')}
                        label="Please perform the Qurbani on my behalf"
                      />
                    </Form.Group>
                    {
                      values.presentAtQurbani === 'yes' && (
                        <p className="additional-info">
                          We have limited capacity for in person Qurbani. The date and time of your Qurbani will be
                          confirmed based on availability once your order has been placed.
                        </p>
                      )
                    }
                    {
                      values.presentAtQurbani === 'no' && (
                        <p className="additional-info">
                          Since we do not have storage facilities suitable for handling Offals, you can either
                          choose
                          the option to be present at the time of Qurbani to collect your Offals, or we will
                          distribute it on your behalf.
                        </p>
                      )
                    }
                    {
                      values.presentAtQurbani && (
                        <>

                          <Form.Group style={{textAlign: 'left', fontSize: '1.2em', paddingBottom: 10}}>
                            <Form.Label>How would you like to receive your meat?</Form.Label>
                            {
                              Object.keys(isDistribtion ? distributeDeliveryOptions : deliveryOptions).map(id => (
                                <Form.Check
                                  type='radio'
                                  name='qurbaniDeliveryOption'
                                  id={id}
                                  key={id}
                                  checked={values.qurbaniDeliveryOption === id}
                                  onChange={(e) => setValue('qurbaniDeliveryOption', id)}
                                  label={deliveryOptions[id]}
                                />
                              ))
                            }
                            {
                              !isDistribtion && <p className="additional-info additional-info-padded">
                                Delivery to your door is free for Stanger, Durban and surrounding areas.
                                For any special delivery requests, please select other and this will
                                be quoted and confirmed separately.
                              </p>
                            }
                          </Form.Group>
                        </>
                      )
                    }
                  </>
                )

              }
              {
                requiresDeliveryInfo && (
                  <Form.Group style={{textAlign: 'left', fontSize: '1.2em', paddingBottom: 10}}>
                    <Form.Label>
                      Which area would you like your order to be delivered to?
                    </Form.Label>
                    <Form.Select
                      value={values.deliveryArea}
                      onChange={(e) => setValue('deliveryArea', e.target.value)}
                    >
                      <option value={''}>Select an option...</option>
                      {
                        deliveryAreas.map(area => (
                          <option value={area} key={area}>{area}</option>
                        ))
                      }
                    </Form.Select>

                  </Form.Group>
                )
              }
              {
                (values.qurbaniDeliveryOption === 'DELIVER_TO_DOOR' || values.service === 'DELIVER_LIVE_ANIMALS') && values.deliveryArea && (
                  <Form.Group style={{textAlign: 'left', fontSize: '1.2em', paddingBottom: 10}}>
                    <Form.Label htmlFor="deliveryAddress">What is your delivery address?</Form.Label>
                    <Form.Control
                      type="text"
                      id="deliveryAddress"
                      value={values.deliveryAddress}
                      onChange={(e) => setValue('deliveryAddress', e.target.value)}
                    />
                    <p className="additional-info">
                      Should your delivery address be outside our service areas, a delivery surcharge may apply.
                    </p>
                  </Form.Group>
                )
              }
              {
                hasSpecialRequest && (
                  <Form.Group style={{textAlign: 'left', fontSize: '1.2em', paddingBottom: 10}}>
                    <Form.Label htmlFor="deliveryAddress">What is your special request?</Form.Label>
                    <Form.Control
                      type="text"
                      id="specialRequest"
                      value={values.specialRequest}
                      onChange={(e) => setValue('specialRequest', e.target.value)}
                    />
                  </Form.Group>

                )
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


export default withRouter(ServiceSelector);

const getRootUrl = (stage) => {
  if (stage === "dev") return "https://api-dev.semoolla.co.za/api";
  else if (stage === "prod") return "https://api.semoolla.co.za/api";

  // Default
  return "http://localhost:8000";
};

export const STORE_OPEN = false;

export const STAGE = process.env.REACT_APP_STAGE || 'dev';
export const API_ROOT_URL = getRootUrl(STAGE);
console.log(API_ROOT_URL, STAGE)

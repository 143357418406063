import {FETCH_CONFIG, setConfig} from "../actions/config";


const mockConfig = {
  "categories": [
    {
      "id": "lambs",
      "name": "Lambs",
      "subtitle": "Premium quality lambs",
      "visible": true,
      "products": [
        {
          "id": "lamb-2024-38kg",
          "name": "Dorper Lamb (+/- 38kg)",
          "product_type": "basic",
          "category_id": "lambs",
          "short_description": "Premium AAA Dorper Lamb +/-38 kg",
          "description": "Premium AAA Dorper Lamb +/-38 kg",
          "price": 2250,
          "visible": true,
        },
        {
          "id": "lamb-2024-42kg",
          "name": "Merino Lamb (+/- 42kg)",
          "product_type": "basic",
          "category_id": "lambs",
          "short_description": "Premium AAA Merino Lamb +/-42kg",
          "description": "Premium AAA Merino Lamb +/-42kg",
          "price": 2550,
          "visible": true,
        },
        {
          "id": "lamb-2024-45kg",
          "name": "Merino Lamb (+/- 45kg)",
          "product_type": "basic",
          "category_id": "lambs",
          "short_description": "Premium AAA Merino Lamb +/-45kg",
          "description": "Premium AAA Merino Lamb +/-45kg",
          "price": 2750,
          "visible": true,
        },
        {
          "id": "lamb-2024-48kg",
          "name": "Merino Lamb (+/- 48kg)",
          "product_type": "basic",
          "category_id": "lambs",
          "short_description": "Premium AAA Merino Lamb +/-48kg",
          "description": "Premium AAA Merino Lamb +/-48kg",
          "price": 2950,
          "visible": true,
        },
        {
          "id": "lamb-2024-52kg",
          "name": "Merino Lamb (+/- 52kg)",
          "product_type": "basic",
          "category_id": "lambs",
          "short_description": "Premium AAA Merino Lamb +/-52kg",
          "description": "Premium AAA Merino Lamb +/-52kg",
          "price": 3250,
          "visible": true,
        },
      ],
      "sort_value": 1
    },
    {
      "id": "goats-and-sheep",
      "name": "Goats and Sheep",
      "subtitle": "Angoras Goats and Sheep",
      "visible": true,
      "products": [
        {
          "id": "angora-2024-distribution",
          "name": "Angora (Distribution Only)",
          "product_type": "basic",
          "category_id": "angora",
          "short_description": "Please place a separate order for this item. This price is for distribution only and is all inclusive",
          "description": "Please place a separate order for this item. This price is for distribution only and is all inclusive",
          "price": 1450,
          "visible": true
        }
      ],
      "sort_value": 2
    },
  ]
}

const config = ({dispatch, getState}) => next => action => {

  next(action);

  if (!([FETCH_CONFIG].includes(action.type)))
    return;

  switch (action.type) {
    case FETCH_CONFIG:
      // next(setConfigLoading(true));
      // next(
      //   apiRequest({
      //     path: "config",
      //     method: API_GET,
      //     success: setConfig,
      //   })
      // );

      next(setConfig(mockConfig));
      break;
    default:
      break;
  }

};

export default config;

import PropTypes from "prop-types";
import React, {useRef} from "react";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";

import {Footer} from "../../components";
import useHeight from "../../hooks/useHeight";

const scrollToRef = (ref) => ref.current.scrollTop = 0;

const MenuLayout = ({children, history}) => {
  const height = useHeight();

  const childWrapperRef = useRef(null);


  React.useEffect(() => {
    scrollToRef(childWrapperRef)
  }, [children]);

  return (
    <div className="menu-page-wrapper text-center" style={{height}}>
      <div className="menu-page">

        <div className="menu-page__header">
          <div className="logo space-mb--5">

            <Link to={process.env.PUBLIC_URL + "/"}>
              <img
                src={process.env.PUBLIC_URL + "/assets/img/KJLBanner.png"}
                className="img-fluid"
                alt=""
              />
            </Link>
          </div>
        </div>

        <div className="menu-page__body"  ref={childWrapperRef}>
          {children}
        </div>

        <Footer/>

      </div>

    </div>
  );
};


MenuLayout.propTypes = {
  children: PropTypes.any
};

export default withRouter(MenuLayout);
